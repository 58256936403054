html, body, #app, #app>div {
  height: 100%
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}
html {
    scroll-behavior: smooth;
}

.content {
  height: 100%;
  background-color: whitesmoke;
  font-family: 'Montserrat', sans-serif;
}

main {
    padding-right: 8%;
    padding-left: 12%;
}

.nav-tabs .nav-item.show, .nav-tabs .nav-link.active {
    border-bottom: 3px solid rgb(24, 69, 218) !important; 
    color: rgb(24, 69, 218) !important;
    font-weight: 400 !important;
}

.nav-link {
    color: rgb(65, 103, 230) !important;
    font-weight: lighter !important;
}

.tabHolder {
    background-color: white
}